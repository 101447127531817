import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { BOOKING_HISTORY, DOWNLOAD_BOOKING_HISTORY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { IoSearchOutline } from 'react-icons/io5';
import fileDownload from 'js-file-download';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { FETCH_CPO } from 'actions/partnerManagement';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const BookingHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [cityLimit, setCityLimit] = useState(false);
  // const [allBookingHistoryListData, setAllBookingHistoryListData] = useState([]);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [isOcpiPartyId, setIsOcpiPartyId] = useState('false');

  const bookingHistoryList = useSelector((state) => state.bookingHistory.bookingHistories);
  const isLoader = useSelector((state) => state.bookingHistory.isLoading);
  const page = useSelector((state) => state.bookingHistory.page);
  const totalData = useSelector((state) => state.bookingHistory.total);
  const limit = useSelector((state) => state.bookingHistory.limit);
  const totalPages = useSelector((state) => state.bookingHistory.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const [selectedOption, setSelectedOption] = useState('station_name');
  const searchOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Booking ID', value: 'booking_id' },
    { label: 'Mobile Number', value: 'phone' },
    { label: 'Station Name', value: 'station_name' },
    { label: 'OCPP ID', value: 'ocpp_id' },
  ];
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));

  const bookingHistory = useCallback(
    (value) => {
      if (searchText) {
        const bookingHistoryData = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: {
            [selectedOption]: searchText,
          },
          is_emsp_based_booking: false,
        };
        dispatch({
          type: BOOKING_HISTORY.REQUEST,
          payload: bookingHistoryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          charging_station: userProfileStations,
          is_emsp_based_booking: false,
        };
        dispatch({
          type: BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText, selectedOption]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (
        advanceFilterData.charging_station === 'All' &&
        advanceFilterData.state === 'All' &&
        advanceFilterData.city === 'All' &&
        advanceFilterData.status_array === 'All'
      ) {
        const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '', status_array: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '', status_array: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else if (advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        bookingHistory(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        charging_station: userProfileStations,
      };
      bookingHistory(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      bookingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText, selectedOption]);

  // useEffect(() => {
  //   setAllBookingHistoryListData(
  //     _.filter(
  //       bookingHistoryList,
  //       (item) =>
  //         startsWith(_.get(item, 'customer_user_booked.name'), searchText) ||
  //         startsWith(_.get(item, 'idTag'), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'estimated_amount'), 2).toString(), searchText) ||
  //         startsWith(moment(_.get(item, 'schedule_datetime')).format('HH:mm'), searchText) ||
  //         startsWith(moment(_.get(item, 'schedule_datetime')).format('DD-MM-YYYY'), searchText) ||
  //         startsWith(_.get(item, 'charger.charging_station.name'), searchText) ||
  //         startsWith(_.get(item, 'id'), searchText) ||
  //         startsWith(_.get(item, 'status'), searchText)
  //     )
  //   );
  // }, [searchText, bookingHistoryList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    bookingHistory({});
  };

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      bookingHistory(data);
    } else {
      bookingHistory();
    }
  }, []);

  // Download Excel Functionality

  const downloadBookingHistory = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (
        advanceFilterData.charging_station === 'All' &&
        advanceFilterData.state === 'All' &&
        advanceFilterData.city === 'All' &&
        advanceFilterData.status_array === 'All'
      ) {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
          status_array: '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = {
          ...advanceFilterData,
          state: '',
          city: '',
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = {
          ...advanceFilterData,
          state: '',
          city: '',
          status_array: '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '', city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.status_array === 'All') {
        const filterData = { ...advanceFilterData, status_array: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          report: 'bookinghistory',
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Booking History Report'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        charging_station: userProfileStations,
        excel: true,
        report: 'bookinghistory',
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Booking History Report'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        report: 'bookinghistory',
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_BOOKING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Booking History Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadBookingHistory();
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && bookingHistory(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        bookingHistory(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && bookingHistory(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        bookingHistory(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (
          advanceFilterData.charging_station === 'All' &&
          advanceFilterData.state === 'All' &&
          advanceFilterData.city === 'All' &&
          advanceFilterData.status_array === 'All'
        ) {
          const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '', status_array: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', city: '', charging_station: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.status_array === 'All') {
          const filterData = { ...advanceFilterData, state: '', city: '', status_array: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.status_array === 'All') {
          const filterData = { ...advanceFilterData, status_array: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.status_array === 'All') {
          const filterData = { ...advanceFilterData, status_array: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.status_array === 'All') {
          const filterData = { ...advanceFilterData, status_array: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else if (advanceFilterData.status_array === 'All') {
          const filterData = { ...advanceFilterData, status_array: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          bookingHistory(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        bookingHistory(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: {
            [selectedOption]: searchText,
          },
          page: page.selected + 1,
        };
        bookingHistory(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        bookingHistory(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        bookingHistory(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText, selectedOption]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <div className="search-box-option">
      <Select options={searchOptions} value={selectedOption} onChange={(value) => setSelectedOption(value)} placeholder="Search Option" />
      <SearchBox
        preIcon={<BsSearch />}
        value={searchText}
        onChange={searchHandler}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const totalStateData = useSelector((state) => state.state.total);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const totalCityData = useSelector((state) => state.cityByState.total);
  const ocpidata = useSelector((state) => state.partnerManagement.partnerDetails);
  const ocpiPage = useSelector((state) => state.partnerManagement.page);
  const ocpiTotalPages = useSelector((state) => state.partnerManagement.totalPages);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', status_array: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
    };
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: stationData });
  }, []);

  const getOcpidata = useCallback(
    (data) =>
      dispatch({
        type: FETCH_CPO.REQUEST,
        payload: data,
      }),
    []
  );

  useEffect(() => {
    if (showAdvanceFilterPopup) {
      getStateByCountry({ country_name: country, limit: totalStateData });
    }
    if (cityLimit) {
      getCityByStateName({ state_names: state, limit: totalCityData });
    }
    getAllStation();
  }, [country, state, totalStateData, totalCityData, cityLimit, getAllStation]);

  useEffect(() => {
    getAllCountry();
    getAllStation();
    getOcpidata({ limit: 999 });
  }, []);

  const allStatus = [
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Rescheduled', value: 'rescheduled' },
  ];

  function formatTime(decimalMinutes) {
    const duration = moment.duration(decimalMinutes, 'minutes');
    const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    return formattedTime;
  }

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.bookingHistory')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).startOf('day')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block">
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon" onClick={downloadFile}>
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingId')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.name')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.mobileNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.otp')}</span>
                                <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationName')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>State</span>
                            </div>
                          </th> */}
                            {/* <th>
                            <div className="sorting">
                              <span>City</span>
                            </div>
                          </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.ocppID')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>Vendor Name</span>
                            </div>
                          </th> */}
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.connectorId')}</span>
                                <span className="ico" onClick={() => handleSorting('connector_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.partyId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingDate')}</span>
                                <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingSlotTime')}</span>
                                <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingType')}</span>
                                <span className="ico" onClick={() => handleSorting('booking_type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.estimatedValue')}</span>
                                <span className="ico" onClick={() => handleSorting('booking_type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.couponId')}</span>
                                <span className="ico" onClick={() => handleSorting('booking_type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.status')}</span>
                                <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={13} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(bookingHistoryList) ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noBookingHistoryData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(bookingHistoryList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;

                              let estimatedValues;
                              if (item.booking_type === 'time') {
                                estimatedValues = formatTime(roundOfDigit(_.get(item, 'estimated_time', ''), 2));
                              } else if (item.booking_type === 'unit') {
                                estimatedValues = `${roundOfDigit(_.get(item, 'estimated_units', ''), 2)} kWh`;
                              } else if (item.booking_type === 'money') {
                                estimatedValues = `₹ ${roundOfDigit(_.get(item, 'estimated_amount', ''), 2)}`;
                              } else if (item.booking_type === 'rfid') {
                                estimatedValues = `₹ ${roundOfDigit(_.get(item, 'estimated_amount', ''), 2)}`;
                              } else {
                                estimatedValues = '-';
                              }

                              return (
                                <>
                                  <tr key={`booking-history-list-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '')}</td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.name', 'Guest User')}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.phone', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                                    {/* <td>{_.get(item, 'charger.charging_station.state', '-')}</td>
                                  <td>{_.get(item, 'charger.charging_station.city', '-')}</td> */}
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '')}</td>
                                    {/* <td>{_.get(item, 'charger.oem.vendor.name')}</td> */}
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'connectorId', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'schedule_datetime') ? (
                                        moment(_.get(item, 'schedule_datetime')).format('DD-MM-YYYY')
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'schedule_datetime') ? (
                                        moment(_.get(item, 'schedule_datetime')).format('HH:mm')
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'booking_type', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : estimatedValues}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'coupon', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'status')}</td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(bookingHistoryList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              const { country, state, city, charging_station, status_array, partyId, ...restValues } = values;

              const filteredValues = {
                ...restValues,
                ...(country && { country }),
                ...(state && { state }),
                ...(city && { city }),
                ...(charging_station && { charging_station }),
                ...(status_array && { status_array }),
                ...(partyId && { partyId }),
              };

              let bookingHistoryData = { ...filteredValues };

              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All' && values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  status_array: '',
                };
              } else if (values.state === 'All' && values.charging_station === 'All' && values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  state: '',
                  status_array: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.state === 'All' && values.city === 'All' && values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  state: '',
                  status_array: '',
                  city: '',
                };
              } else if (values.city === 'All' && values.charging_station === 'All' && values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  city: '',
                  status_array: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.city === 'All' && values.charging_station === 'All' && values.state === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  city: '',
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.state === 'All' && values.city === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  state: '',
                  city: '',
                };
              } else if (values.city === 'All' && values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  status_array: '',
                  city: '',
                };
              } else if (values.charging_station === 'All' && values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  status_array: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.state === 'All' && values.charging_station === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.city === 'All' && values.charging_station === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.city === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  city: '',
                };
              } else if (values.state === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  state: '',
                };
              } else if (values.charging_station === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
              } else if (values.status_array === 'All') {
                bookingHistoryData = {
                  ...bookingHistoryData,
                  status_array: '',
                };
              }

              bookingHistory(bookingHistoryData);
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val, limit: 999 });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={
                      !changeState
                        ? values.country &&
                          !_.isEmpty(allState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                          !_.isEmpty(allCityByState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allCityByState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder={t('placeHolder.selectCity')}
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getAllStation({ city_array: '' });
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`city`, '');
                      } else {
                        getAllStation({ city_array: val });
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue('city', val);
                      }
                      setFieldValue(`charging_station`, '');
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !changeStation
                          ? (!_.isEmpty(_.get(values, 'city')) && !_.isEmpty(stationList) && _.get(values, 'state') === 'All') ||
                            _.get(stationList, 'city') === _.get(values, 'city')
                            ? [
                                // { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station?.name, value: station?.id };
                                }),
                              ]
                            : [
                                // { label: 'All', value: 'All' },
                                ..._.map(
                                  stationList?.filter((o) => o.state === values.state[0]),
                                  (station) => {
                                    return { label: station?.name, value: station?.id };
                                  }
                                ),
                              ]
                          : !_.isEmpty(_.get(values, 'city')) &&
                            !_.isEmpty(_.get(values, 'state')) &&
                            values.country &&
                            !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, '');
                        } else {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, val);
                        }
                      }}
                    />
                    <Select
                      label={t('Charger Id')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return station.chargers && station.chargers.length > 0
                                    ? { label: station.chargers[0].charger_id, value: station.chargers[0].id }
                                    : null;
                                }).filter(Boolean), // Remove null values
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return station.chargers && station.chargers.length > 0
                                    ? { label: station.chargers[0].charger_id, value: station.chargers[0].id }
                                    : null;
                                }).filter(Boolean), // Remove null values
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('Charger Id')}
                      name="charger_id"
                      isMulti
                      value={values.charger_id}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charger_id`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charger_id`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charger_id', val);
                        }
                      }}
                    />
                    <Select
                      label="Status"
                      options={
                        !changeStatus
                          ? !_.isEmpty(allStatus) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStatus, (status) => {
                                return { label: status.label, value: status.value };
                              }),
                            ]
                          : !_.isEmpty(allStatus) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Status"
                      name="status_array"
                      isMulti
                      value={values.status_array}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStatus(true);
                          setFieldValue(`status_array`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`status_array`, '');
                          setChangeStatus(false);
                        } else {
                          setChangeStatus(false);
                          setFieldValue('status_array', val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.ocpiBooking')}
                      options={[
                        { label: 'true', value: 'true' },
                        { label: 'false', value: 'false' },
                      ]}
                      placeholder={t('placeHolder.selectAnyone')}
                      name="is_ocpi_based_booking"
                      value={values.is_ocpi_based_booking}
                      onChange={(val) => {
                        setFieldValue('is_ocpi_based_booking', val);
                        setIsOcpiPartyId(val);
                      }}
                    />
                    {isOcpiPartyId == 'true' ? (
                      <Select
                        label={t('filters.ocpiParties')}
                        options={[
                          ..._.map(ocpidata, (data) => {
                            return { label: data.partyId, value: data.partyId };
                          }),
                        ]}
                        placeholder={t('filters.selectParty')}
                        name="partyId"
                        onMenuScrollDown={true}
                        getDataOnScrollDown={getOcpidata}
                        page={ocpiPage}
                        totalPage={ocpiTotalPages}
                        value={values.partyId}
                        onChange={(val) => {
                          setFieldValue('partyId', val);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      bookingHistory();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default BookingHistory;
