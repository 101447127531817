import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useEffect, useCallback, useState, useContext } from 'react';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { DOWNLOAD_CUSTOMER_BOOKING, FETCH_CUSTOMER_BOOKING, RESCHEDULE_CUSTOMER_BOOKING, CANCEL_CUSTOMER_BOOKING } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import { BiSort } from 'react-icons/bi';
import { FaChargingStation } from 'react-icons/fa';
import { roundOfDigit } from 'components/common/utils';
import moment from 'moment-timezone';
import DropDown from 'components/inputs/DropDown';
import { Col, Row, Spinner, Modal } from 'react-bootstrap';
import { BsFilter } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import Button from 'components/inputs/Button';
import fileDownload from 'js-file-download';
// import { useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { useTranslation } from 'react-i18next';
import { GrMoreVertical } from 'react-icons/gr';
import BookingModal from './BookingModal';
import { toast } from 'react-toastify';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { Formik, Form } from 'formik';
import { MdClose } from 'react-icons/md';
import * as Yup from 'yup';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const CustomerBooking = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const { customerId } = useParams();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [fieldValue, setFieldValue] = useState('all');
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showTimingModal, setShowTimingModal] = useState(false);
  const [id, setId] = useState('');

  const customerBookingList = useSelector((state) => state.customerBooking.customerBooking);
  const page = useSelector((state) => state.customerBooking.page);
  const totalData = useSelector((state) => state.customerBooking.total);
  const limit = useSelector((state) => state.customerBooking.limit);
  const totalPages = useSelector((state) => state.customerBooking.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);

  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] =useState(moment().tz(countryTimezone).startOf('week').utc());
  const [endDate, setEndDate] = useState(moment().tz(countryTimezone).endOf('day').utc());


  const getCustomerBooking = useCallback(
    (data) => {
      const bookingData = {
        ...data,
        id: customerId,
      };
      dispatch({ type: FETCH_CUSTOMER_BOOKING.REQUEST, payload: bookingData });
    },
    [customerId]
  );

  const cancelCustomerBooking = useCallback(
    (data) => {
      const bookingData = {
        ...data,
        userId: customerId,
      };
      dispatch({
        type: CANCEL_CUSTOMER_BOOKING.REQUEST,
        payload: bookingData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('Booking Cancelled successfully');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            navigateTo(`/customerProfile/${customerId}`);
          }
        },
      });
    },
    [customerId]
  );

  const rescheduleCustomerBooking = useCallback(
    (data) => {
      const bookingData = {
        ...data,
        userId: customerId,
      };
      dispatch({ type: RESCHEDULE_CUSTOMER_BOOKING.REQUEST, payload: bookingData });
    },
    [customerId]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
      };
      getCustomerBooking(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  useEffect(() => {
    getCustomerBooking(); // Call getCustomerBooking when the component first loads
    dateFilter(); // Call dateFilter after invoking getCustomerBooking
  }, []);

  const handleSearchClick = () => {
    if (fieldValue && startDate && endDate) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        status: fieldValue,
      };
      getCustomerBooking(filterDateData);
    } else if (fieldValue) {
      const filterDateType = {
        status: fieldValue,
      };
      getCustomerBooking(filterDateType);
      dateFilter(); // Call dateFilter after invoking getCustomerBooking
    } else {
      getCustomerBooking();
    }
  };
  const handlePageClick = (page) => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
    } else if (page && fieldValue && startDate && endDate) {
      const filterData = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        status: fieldValue,
        page: page.selected + 1,
      };
      const allStatus = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : endDate && getCustomerBooking(filterData);
    } else if (page && startDate && endDate) {
      const filterData = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        page: page.selected + 1,
      };
      endDate && getCustomerBooking(filterData);
    } else if (page && fieldValue) {
      const filterDateType = {
        status: fieldValue,
        page: page.selected + 1,
      };
      const allStatus = {
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : getCustomerBooking(filterDateType);
    } else if (page) {
      const data = {
        page: page.selected + 1,
      };
      getCustomerBooking(data);
    }
  };

  const getSortByItem = (name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page && startDate && endDate && fieldValue) {
      const data = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        status: fieldValue,
      };
      const allStatus = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : endDate && getCustomerBooking(data);
    } else if (page && startDate && endDate) {
      const data = {
        from: moment(startDate).tz(countryTimezone).utc().toDate(),
        to: moment(endDate).tz(countryTimezone).utc().toDate(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      endDate && getCustomerBooking(data);
    } else if (page && fieldValue) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        status: fieldValue,
        page: page.selected + 1,
      };
      const allStatus = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : getCustomerBooking(data);
    } else if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCustomerBooking(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      endDate && getCustomerBooking(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCustomerBooking(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const filterData = [
    { label: 'all', value: 'all' },
    { label: 'completed', value: 'completed' },
    { label: 'scheduled', value: 'scheduled' },
    { label: 'cancelled', value: 'cancelled' },
    { label: 'in_progress', value: 'in_progress' },
  ];

  const downloadCustomerBooking = useCallback(
    (data = {}) => {
      if (fieldValue && startDate && endDate) {
        const bookingData = {
          id: customerId,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc().toDate(),
          to: moment(endDate).tz(countryTimezone).utc().toDate(),
          status: fieldValue,
          ...data,
        };
        const allData = {
          id: customerId,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc().toDate(),
          to: moment(endDate).tz(countryTimezone).utc().toDate(),
          ...data,
        };
        const customerBookingData = fieldValue === 'all' ? allData : bookingData;
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      } else if (startDate && endDate) {
        const customerBookingData = {
          id: customerId,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc().toDate(),
          to: moment(endDate).tz(countryTimezone).utc().toDate(),
          ...data,
        };
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      } else if (fieldValue) {
        const bookingData = {
          id: customerId,
          excel: true,
          status: fieldValue,
          ...data,
        };
        const allData = {
          id: customerId,
          excel: true,
          ...data,
        };
        const customerBookingData = fieldValue === 'all' ? allData : bookingData;
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      } else {
        const customerBookingData = {
          id: customerId,
          excel: true,
          ...data,
        };
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      }
    },
    [startDate, endDate, fieldValue]
  );

  const RescheduleBookingSchema = Yup.object().shape({
    time: Yup.string().required(t('scheduleBooking.time')),
    date: Yup.string().required(t('scheduleBooking.date')),
  });

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleCloseModel = () => {
    setShowBookingModal(false);
    setShowTimingModal(false);
  };
  const handleClick = () => {
    cancelCustomerBooking(id);
    setShowBookingModal(false);
  };
  const downloadFile = () => {
    downloadCustomerBooking();
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <Card className="customerBooking-table">
            <Row className="customerBooking-table-heading-row">
              <Col xl={2} lg={6} md={6}>
                <div className="customerBooking-table-heading">{t('header.bookingDetails')}</div>
              </Col>
              <Col xl={2} lg={6} md={6}>
                <DropDown
                  popupClass="main-menu bottom scrollable"
                  trigger={
                    <>
                      <div className="customerBooking-filter-block">
                        <div className="customerBooking-filter-icon">
                          <BsFilter />
                          <span className="customerBooking-filter-label">{_.startCase(fieldValue)}</span>
                        </div>
                        <div className="customerBooking-arrow__icon">
                          <AiFillCaretDown />
                        </div>
                      </div>
                    </>
                  }
                  triggerClass="customerBooking-filter-dropdown"
                >
                  {_.map(filterData, (item, index) => (
                    <ul key={`${index}`}>
                      <li
                        className="item"
                        onClick={() => {
                          setFieldValue(item.value);
                        }}
                      >
                        {_.startCase(item.label)}
                      </li>
                    </ul>
                  ))}
                </DropDown>
              </Col>
              <Col xl={6} lg={9} md={9}>
                <DateTimePicker
                  onChangeOfStartDate={(item) => setStartDate(item)}
                  onChangeOfEndDate={(item) => setEndDate(item)}
                  initialValueOfStartDate={moment().startOf('week')}
                  initialValueOfEndDate={moment().endOf('day')}
                />
              </Col>
              <Col xl={1} lg={2} md={2}>
                <div className="customerBooking-search-box" onClick={handleSearchClick}>
                  <Button className="customerBooking-search-button">{t('button.search')}</Button>
                </div>
              </Col>
              <Col xl={1} lg={1} md={1}>
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <div className="customerBooking-excel-icon" onClick={downloadFile}>
                    <PiMicrosoftExcelLogo size={35} color={'#5dad5d'} />
                  </div>
                )}
              </Col>
            </Row>
            <div className="customerBooking-list-table">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.srNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.stationName')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.ocppId')}</span>
                          <span className="ico" onClick={() => handleSorting('connectorId', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.status')}</span>
                          <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.bookingAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.estimatedDiscount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.userPaid')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.actualDiscount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.serviceCharge')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.taxableAmount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.gst')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.totalAmount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.extraAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.refundAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.stopReason')}</span>
                          <span className="ico" onClick={() => handleSorting('stop_reason', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.otp')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.rfid')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.sessionConsumption')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.slotTime')}</span>
                          <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.startChargingTime')}</span>
                          <span className="ico" onClick={() => handleSorting('booking_start', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.stopChargingTime')}</span>
                          <span className="ico" onClick={() => handleSorting('booking_stop', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.sessionDuration')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.action')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(customerBookingList) ? (
                      <tr>
                        <td colSpan={10} className="border-0">
                          <div className="empty-data-block">{t('bookingDetails.noData')}</div>
                        </td>
                      </tr>
                    ) : (
                      _.map(customerBookingList, (item, index) => {
                        const serial_num = limit * (page - 1) + index;
                        const otp = _.get(item, 'is_rfid_based_booking') === false ? _.get(item, 'idTag') : '-';
                        const rfid = _.get(item, 'is_rfid_based_booking') === true ? _.get(item, 'idTag') : '-';
                        const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart'))
                          ? '-'
                          : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);
                        // const refundAmount = isNaN(_.get(item, 'estimated_amount') - _.get(item, 'invoice.amount'))
                        //   ? '-'
                        //   : roundOfDigit(_.get(item, 'estimated_amount') - _.get(item, 'invoice.amount'), 2);
                        // const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                        //   ? 0
                        //   : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);

                        const bookingStart = moment(_.get(item, 'booking_start', '-')).toISOString();
                        const bookingStop = moment(_.get(item, 'booking_stop', '-')).toISOString();
                        // const bookingDuration = isNaN(moment(bookingStop).diff(moment(bookingStart)))
                        //   ? '00:00:00'
                        //   : moment(bookingStop).diff(moment(bookingStart));

                        function calculateTimeDifference(start, end) {
                          const diffInMilliseconds = Math.abs(end - start);
                          const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
                            .toString()
                            .padStart(2, '0');
                          const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
                            .toString()
                            .padStart(2, '0');
                          const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)
                            .toString()
                            .padStart(2, '0');

                          return { hours, minutes, seconds };
                        }
                        const startDate = new Date(bookingStart);
                        const endDate = new Date(bookingStop);
                        const timeDifference = calculateTimeDifference(startDate, endDate);

                        const usedamount = _.get(item, 'invoice.amount', '');
                        const paidamount = _.get(item, 'estimated_amount', '');
                        const estimated_chargecoins = _.get(item, 'chargecoins_estimated', '');
                        const refund = paidamount - usedamount;
                        const bookingamount = estimated_chargecoins + paidamount;
                        const taxableAmount = _.get(item, 'invoice.service_charge', '') - _.get(item, 'invoice.chargecoins_used', '');
                        const gst = _.get(item, 'invoice.gst', '');

                        function calculateRefundAmount(status, refund) {
                          if (status === 'in_progress') {
                            return '-';
                          } else if (refund <= 0) {
                            return '-';
                          } else {
                            return roundOfDigit(Math.round(refund * 100) / 100 + _.get(item, 'invoice.chargecoins_used', ''), 2);
                          }
                        }

                        function calculateActualDiscount(status, chargerBooking) {
                          if (status === 'in_progress' || status === 'cancelled' || status === 'scheduled' || status === 'rescheduled') {
                            return 0;
                          } else if (chargerBooking.invoice?.coupon && chargerBooking.status !== 'completed') {
                            return 0;
                          } else if (
                            chargerBooking.invoice?.coupon &&
                            chargerBooking.invoice?.coupon_discount.discount_type === 'direct_discount' &&
                            chargerBooking.status == 'completed'
                          ) {
                            return roundOfDigit(chargerBooking.invoice?.coupon_discount.discount_value, 2);
                          } else {
                            return roundOfDigit(chargerBooking.invoice?.chargecoins_used || 0, 2);
                          }
                        }

                        return (
                          <tr key={`booking-data-item-${index}`}>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>

                            <td
                              className="station-link"
                              onClick={() =>
                                navigateTo(`/stations/${_.get(item, 'charger.charging_station.id', '')}`, {
                                  backTo: 'chargerStatus',
                                  activeTab: 'reservations',
                                })
                              }
                            >
                              {_.get(item, 'charger.charging_station.name') && (
                                <span className="item-icon">
                                  {isLoading ? <Skeleton circle="true" height={20} width={20} /> : <FaChargingStation size={20} />}
                                </span>
                              )}
                              <span>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '-')}</span>
                            </td>

                            <td
                              className="station-link"
                              onClick={() =>
                                navigateTo(`/charger/${_.get(item, 'charger.id', '')}`, {
                                  backTo: 'chargerStatus',
                                })
                              }
                            >
                              {isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '')}(
                              {_.get(item, 'connectorId', '')})
                            </td>

                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'status', '-')}</td>
                            {/* <td>{`${roundOfDigit(_.get(item, 'invoice.amount', ''), 2)} - ${unitConsumed} kWh - ${chargingDurationTime}`}</td> */}
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(bookingamount, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(estimated_chargecoins, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(bookingamount - estimated_chargecoins, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : calculateActualDiscount(item.status, item)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'invoice.service_charge', ''), 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(taxableAmount, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(gst, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(taxableAmount + gst, 2)}</td>

                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : roundOfDigit(_.get(item, 'invoice.amount', '') - bookingamount, 2) <= 0 ? (
                                '-'
                              ) : (
                                roundOfDigit(_.get(item, 'invoice.amount', '') - bookingamount, 2)
                              )}
                            </td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : calculateRefundAmount(item.status, refund)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : otp}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : rfid}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : unitConsumed}</td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.schedule_datetime ? (
                                moment(item.schedule_datetime).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.booking_start ? (
                                moment(item.booking_start).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.booking_stop ? (
                                moment(item.booking_stop).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : (
                                <>
                                  {timeDifference.hours}:{timeDifference.minutes}:{timeDifference.seconds}
                                </>
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : (
                                _.get(item, 'status') === 'scheduled' && (
                                  <DropDown
                                    trigger={
                                      <span className="dropdown-icon">
                                        <GrMoreVertical />
                                      </span>
                                    }
                                    popupClass="bottom-left"
                                  >
                                    <ul>
                                      <li
                                        className="item"
                                        onClick={() => {
                                          setShowTimingModal(true);
                                          setId(_.get(item, 'id', ''));
                                        }}
                                      >
                                        {t('bookingDetails.rescheduleBooking')}
                                      </li>

                                      <li
                                        className="item"
                                        onClick={() => {
                                          setShowBookingModal(true);
                                          setId({ id: _.get(item, 'id', '') });
                                        }}
                                      >
                                        {t('bookingDetails.cancelBooking')}
                                      </li>
                                    </ul>
                                  </DropDown>
                                )
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {!_.isEmpty(customerBookingList) && (
              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={(pageVal) => handlePageClick(pageVal)}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            )}
          </Card>
        </SkeletonTheme>

        {showBookingModal && (
          <Modal show={showBookingModal} centered onHide={handleCloseModel}>
            <BookingModal subTitle="Cancel" title="cancel" onClose={handleCloseModel} bookingModel={handleClick} />
          </Modal>
        )}
        {showTimingModal && (
          <Modal show={showTimingModal} size="lg" centered>
            <div className="customer-form-page__main">
              <Card>
                <div className="customer-form-header__block">
                  <div className="customer-header-name">
                    <span>{t('bookingDetails.rescheduleBooking')} </span>
                  </div>
                  <div className="close___btn">
                    <MdClose size={30} color="#BE210B" onClick={handleCloseModel} />
                  </div>
                </div>
                <div className="customer-form-body__block">
                  <div className="customer-form--block">
                    <Formik
                      initialValues={{
                        schedule_datetime: '',
                        date: getCurrentDate(),
                        time: '',
                      }}
                      validationSchema={RescheduleBookingSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        const istDate = new Date(`${values.date}T${values.time}:00.000Z`);
                        const formattedDate = new Date(istDate.getTime() - istDate.getTimezoneOffset() * -60000).toISOString();
                        delete values.date;
                        delete values.time;
                        rescheduleCustomerBooking({
                          ...values,
                          schedule_datetime: formattedDate,
                          id: id,
                        });
                        setSubmitting(false);
                        setShowTimingModal(false);
                      }}
                    >
                      {({ values, handleSubmit, setFieldValue, isSubmitting, touched, errors }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col>
                              <span className="font-color">{t('placeHolder.scheduleDateTime')}</span>
                              <div>&nbsp;</div>
                              <div className="time_sec">
                                <div className="custome_time">
                                  <input
                                    className="ct_selecter"
                                    name="date"
                                    type="Date"
                                    value={values.date}
                                    min={getCurrentDate()}
                                    onChange={(val) => {
                                      setFieldValue(`date`, val.target.value);
                                    }}
                                  />
                                  <div style={{ color: '#dc4949', fontSize: 10 }}>{errors.date && touched.date ? errors.date : null}</div>
                                </div>
                                <div className="to_sec"></div>
                                <spdivan className="custome_time">
                                  <input
                                    className="ct_selecter"
                                    type="time"
                                    value={values.time}
                                    onChange={(val) => {
                                      const selectedTime = val.target.value;
                                      setFieldValue('time', selectedTime);
                                    }}
                                  />
                                  <div style={{ color: '#dc4949', fontSize: 10 }}>{errors.time && touched.time ? errors.time : null}</div>
                                </spdivan>
                              </div>
                            </Col>
                          </Row>
                          <div className="form-btn__block">
                            <Button className="form_btn cancel____btn" onClick={handleCloseModel}>
                              {t('button.cancel')}
                            </Button>
                            <Button type="submit" disabled={isSubmitting} className="form_btn">
                              {t('button.reschedule')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Card>
            </div>
          </Modal>
        )}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default CustomerBooking;
