import React, { useState, useCallback, useEffect, useContext } from 'react';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import LayoutContainer from 'components/layout/LayoutContainer';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import DateTimePicker from 'components/inputs/DateTimePicker';
import Button from 'components/inputs/Button';
import SearchBox from 'components/general/SearchBox';
import Card from 'components/inputs/Card';
import DropDown from 'components/inputs/DropDown';
import AdminHeader from 'components/header';

import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import { GrMoreVertical } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit2 } from 'react-icons/fi';
import { FaChargingStation } from 'react-icons/fa';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { startsWith } from 'components/common/utils';
import { CREATE_STATUS_CODE, DELETE_STATUS_CODE } from 'components/common/constant';
import {
  DELETE_CPO,
  FETCH_CPO,
  HANDSHAKE,
  PULL_TOKEN,
  GET_ENDPOINT,
  PULL_CDRS,
  PULL_LOCATION,
  PULL_SESSIONS,
  PULL_TARIFF,
} from 'actions/partnerManagement';
import { Formik, Form } from 'formik';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const Emsp = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [emspId, setEmspId] = useState('');

  const [selectDateModal, setSelectDateModal] = useState({ isVisiblity: false, modalName: '', payload: null });
  const [endpointModel, setEndpointModal] = useState({ isVisiblity: false, modalName: '', payload: null });

  const EMSPdata = useSelector((state) => state.partnerManagement.partnerDetails);
  const isLoader = useSelector((state) => state.partnerManagement.isLoading);
  const page = useSelector((state) => state.partnerManagement.page);
  const totalData = useSelector((state) => state.partnerManagement.total);
  const limit = useSelector((state) => state.partnerManagement.limit);
  const totalPages = useSelector((state) => state.partnerManagement.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const [allOcpiData, setAllOcpiData] = useState([]);

  const getEMSPdata = useCallback((data = {}) => {
    if (searchText) {
      const searchTextData = {
        ...data,
        search: searchText,
        limit: 999,
      };
      dispatch({ type: FETCH_CPO.REQUEST, payload: searchTextData });
    } else {
      dispatch({
        type: FETCH_CPO.REQUEST,
        payload: data ? data : {},
      });
    }
  });

  // const getPartnerGroup = useCallback(
  //   (data) => {
  //     if (searchText) {
  //       const searchUserGroupData = {
  //         ...data,
  //         search: searchText,
  //       };
  //       dispatch({
  //         type: FETCH_OCPI_PARTNER_GROUP,
  //         payload: searchUserGroupData,
  //         cb: (res) => {
  //           if (res) {
  //             if (_.get(res, 'status') === 200) {
  //               setPageWiseUserGroupData(res.data.results);
  //             }
  //           }
  //         },
  //       });
  //     } else {
  //       dispatch({
  //         type: FETCH_OCPI_PARTNER_GROUP,
  //         payload: data ? data : {},
  //         cb: (res) => {
  //           if (res) {
  //             if (_.get(res, 'status') === 200) {
  //               setPageWiseUserGroupData(res.data.results);
  //             }
  //           }
  //         },
  //       });
  //     }
  //   },
  //   [pageWiseUserGroupData, searchText]
  // );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setEmspId('');
  };

  const deleteEmsp = useCallback(
    () =>
      dispatch({
        type: DELETE_CPO.REQUEST,
        payload: emspId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getEMSPdata({ page: 1 });
          }
        },
      }),
    [emspId]
  );

  useEffect(() => {
    getEMSPdata();
  }, [searchText]);

  const handleSearch = () => {
    getEMSPdata();
  };

  useEffect(() => {
    if (!searchText) {
      setAllOcpiData(EMSPdata);
      return;
    } else {
      setAllOcpiData(_.filter(EMSPdata, (data) => startsWith(_.get(data, 'partyId', ''), searchText)));
    }
  }, [searchText, EMSPdata]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getEMSPdata(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getEMSPdata(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getEMSPdata(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };
  const getEndpointByToken = useCallback(
    (token) =>
      dispatch({
        type: GET_ENDPOINT.REQUEST,
        payload: token,
        cb: (res) => {
          if (_.get(res, 'status', '') === 200) {
            setEndpointModal({ isVisiblity: true, modalName: 'endpoints' });
          }
        },
      }),
    []
  );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.ocpiPartner')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="user-group-page_main">
              <div className="user-group-main-block">
                <Card>
                  <div className="user-group-block">
                    <div className="user-group-search-box">
                      <Row>
                        <Col xl={10}>
                          <SearchBox
                            preIcon={<BsSearch />}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch();
                              }
                            }}
                          />
                        </Col>
                        <Col xl={2}>
                          <Button className="user-group-btn" onClick={() => navigateTo('/OCPI-Partner/addPartner')}>
                            {t('button.addPartners')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <div className="user-group-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.role')}</span>
                                  <span className="ico" onClick={() => handleSorting('role', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.partnerName')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.partyId')}</span>
                                  <span className="ico" onClick={() => handleSorting('partyId', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.countryCode')}</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.website_url')}</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.handshaking')}</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.owner')}</span>
                                  <span className="ico" onClick={() => handleSorting('owner', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.actions')}</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addCPO.more')}</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <div className="loader--block">
                                    <Skeleton height={15} width={100} />
                                  </div>
                                </td>
                              </tr>
                            ) : _.isEmpty(allOcpiData) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('addCPO.noOCPI')}</div>
                                </td>
                              </tr>
                            ) : (
                              allOcpiData?.map((data, index) => (
                                <tr key={index}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'role')}</td>

                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'partner_name')}</td>

                                  {_.isEmpty(_.get(data, 'partyId')) ? (
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'partyId')}</td>
                                  ) : (
                                    <td
                                      className="charger-link"
                                      onClick={() => {
                                        navigateTo(`/ocpiPartyInfo/${_.get(data, 'id', '')}`);
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'partyId')}
                                    </td>
                                  )}
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'countryCode')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'website_url')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'handshake_completed') ? 'true' : 'false'}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'owner')}</td>

                                  <td>
                                    <span
                                      className="user-group-table-editIcon"
                                      onClick={() => {
                                        const ocpiId = _.get(data, 'id');
                                        navigateTo(`/stationAccessToPartner/${ocpiId}`);
                                      }}
                                    >
                                      {isLoader ? (
                                        <Skeleton height={15} width={15} circle="true" />
                                      ) : (
                                        <FaChargingStation size={24} title="Station Access" />
                                      )}
                                    </span>
                                    <span
                                      className="user-group-table-editIcon"
                                      onClick={() => {
                                        const ocpiId = _.get(data, 'id');
                                        navigateTo(`/OCPI-Partner/${ocpiId}`);
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={15} circle="true" /> : <FiEdit2 title="Edit" />}
                                    </span>
                                    <span
                                      className="user-group-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setEmspId(_.get(data, 'id'));
                                      }}
                                    >
                                      {isLoader ? <Skeleton height={15} width={15} circle="true" /> : <RiDeleteBinLine title="Delete" />}
                                    </span>
                                  </td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(data, 'role') === 'EMSP' ? (
                                      <DropDown
                                        trigger={
                                          <span className="dropdown-icon">
                                            <GrMoreVertical />
                                          </span>
                                        }
                                        popupClass="bottom-left"
                                      >
                                        <ul>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: HANDSHAKE.REQUEST,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            {t('addCPO.performHandShaking')}
                                          </li>

                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              getEndpointByToken(id);
                                            }}
                                          >
                                            {t('addCPO.viewEndponits')}
                                          </li>

                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: PULL_TOKEN.REQUEST,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            {t('addCPO.pullToken')}
                                          </li>
                                        </ul>
                                      </DropDown>
                                    ) : (
                                      <DropDown
                                        trigger={
                                          <span className="dropdown-icon">
                                            <GrMoreVertical />
                                          </span>
                                        }
                                        popupClass="bottom-left"
                                      >
                                        <ul>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: HANDSHAKE.REQUEST,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            {t('addCPO.performHandShaking')}
                                          </li>

                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              getEndpointByToken(id);
                                            }}
                                          >
                                            {t('addCPO.viewEndponits')}
                                          </li>

                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: PULL_LOCATION.REQUEST,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            {t('addCPO.pullLocation')}
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              const id = _.get(data, 'id');
                                              dispatch({
                                                type: PULL_TARIFF.REQUEST,
                                                payload: id,
                                              });
                                            }}
                                          >
                                            {t('addCPO.pullTariffs')}
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              setSelectDateModal({ isVisiblity: true, modalName: 'session', payload: _.get(data, 'id') });
                                            }}
                                          >
                                            {t('addCPO.pullSessions')}
                                          </li>
                                          <li
                                            className="item"
                                            onClick={() => {
                                              setSelectDateModal({ isVisiblity: true, modalName: 'cdrs', payload: _.get(data, 'id') });
                                            }}
                                          >
                                            {t('addCPO.pullCdrs')}
                                          </li>
                                        </ul>
                                      </DropDown>
                                    )}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(EMSPdata) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title={'OCPI Partner'} onClose={handleCloseDeleteModel} onRemove={deleteEmsp} />
                      </Modal>
                    )}

                    {selectDateModal.isVisiblity && (
                      <Modal show={selectDateModal.isVisiblity} size="lg" centered>
                        <PushDataForm
                          onClose={() => {
                            setSelectDateModal({ ...selectDateModal, isVisiblity: false });
                          }}
                          modalData={selectDateModal}
                        />
                      </Modal>
                    )}

                    {selectDateModal.isVisiblity && (
                      <Modal show={selectDateModal.isVisiblity} size="lg" centered>
                        <PushDataForm
                          onClose={() => {
                            setSelectDateModal({ ...selectDateModal, isVisiblity: false });
                          }}
                          modalData={selectDateModal}
                        />
                      </Modal>
                    )}

                    {endpointModel.isVisiblity && (
                      <Modal show={endpointModel.isVisiblity} size="xl" centered>
                        <ViewDataForm
                          onClose={() => {
                            setEndpointModal({ ...endpointModel, isVisiblity: false });
                          }}
                          modalData={endpointModel}
                        />
                      </Modal>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default Emsp;

const PushDataForm = ({ onClose = _.noop(), modalData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialValues = {
    dateFrom: moment(new Date()).startOf('day').utc().format('YYYY-MM-DD'),
    dateTo: moment(new Date()).startOf('day').utc().format('YYYY-MM-DD'),
    id: modalData.payload,
  };
  const profileData = useSelector((state) => state.profile.userProfile);
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));

  // pull session
  const pullSession = useCallback((data) => {
    dispatch({
      type: PULL_SESSIONS.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  });

  // pull CDRS
  const pullCdrs = useCallback((data) => {
    dispatch({
      type: PULL_CDRS.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  });

  // useEffect(() => {
  //   console.log('initialValues: ', initialValues);l
  // }, [startDate, endDate]);

  return (
    <React.Fragment>
      <div className="group-form-page__main">
        <Card>
          <div className="group-form-header__block">
            <div className="group-header-name">
              <span>
                {t('addCPO.pull')} {modalData.modalName == 'session' ? 'session' : 'cdrs'}{' '}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="group-form-body__block">
            <div className="group-form--block">
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  modalData.modalName == 'session' ? pullSession(values) : pullCdrs(values);
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, setValues }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setValues({ ...values, dateFrom: moment(item).tz(countryTimezone).format('YYYY-MM-DD') })}
                          onChangeOfEndDate={(item) => setValues({ ...values, dateTo: moment(item).tz(countryTimezone).format('YYYY-MM-DD') })}
                          initialValueOfStartDate={moment(new Date()).tz(countryTimezone).startOf('day').utc()}
                          initialValueOfEndDate={moment(new Date()).tz(countryTimezone).startOf('day').utc()}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button type="submit" className="form_btn">
                        {t('button.continue')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
PushDataForm.propTypes = {
  modalData: PropTypes.object,
  onClose: PropTypes.func,
};

//view endpoints

const ViewDataForm = ({ onClose = _.noop() }) => {
  // const dispatch = useDispatch();
  const viewEndpoints = useSelector((state) => state.ocpiPullRequest?.endpoints);

  const initialValues = {
    version: '',
    identifier: '',
    role: '',
    url: '',
  };

  // const data = _.map(
  //   _.filter(viewEndpoints, (data) => data.token === modalData.tokenId),
  //   (data) => data
  // );

  // const getEndpoint = useCallback((data) => {
  //   dispatch({
  //     type: VIEW_ENDPOINTS,
  //     payload: data,
  //     cb: (res) => {
  //       if (_.get(res, 'status', '') === CREATE_STATUS_CODE) {
  //         onClose();
  //       }
  //     },
  //   });
  // });

  // useEffect(() => {
  //   getEndpoint();
  // }, []);

  return (
    <React.Fragment>
      <div className="group-form-page__main">
        {viewEndpoints.isLoading ? (
          <div className="loader--block">
            <Skeleton height={15} width={100} />
          </div>
        ) : (
          <Card>
            <div className="group-form-header__block">
              <div className="group-header-name">
                <span>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : t('addCPO.viewEndponits')} </span>
              </div>
              <div className="close___btn">
                <MdClose size={30} color="#BE210B" onClick={onClose} />
              </div>
            </div>
            <div className="group-form-body__block">
              <div className="group-form--block">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          {t('addCPO.version')} {_.get(viewEndpoints, 'version')}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Card>
                            <div className="user-group-table">
                              <div className="table-responsive">
                                <table className="record-list-table">
                                  <thead>
                                    <tr>
                                      <th>
                                        <div className="sorting">
                                          <span>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : t('addCPO.identifier')}</span>
                                        </div>
                                      </th>
                                      <th>
                                        <div className="sorting">
                                          <span>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : t('addCPO.role')}</span>
                                        </div>
                                      </th>
                                      <th className="action-col">
                                        <div>
                                          <span>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : t('addCPO.url')}</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_.map(viewEndpoints.endpoints, (data) => (
                                      <tr key={_.get(data, '_id')}>
                                        <td>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : _.get(data, 'identifier')}</td>
                                        <td>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : _.get(data, 'role')}</td>
                                        <td>{viewEndpoints.isLoading ? <Skeleton height={15} width={100} /> : _.get(data, 'url')}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <div className="form-btn__block">
                        <Button type="submit" className="form_btn">
                          {t('button.continue')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};
ViewDataForm.propTypes = {
  onClose: PropTypes.func,
};