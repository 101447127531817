import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_OWNERSHIP_TYPE, FETCH_OWNERSHIP_TYPE, GET_OWNERSHIP_TYPE } from 'actions/ownershipType';
import OwnershipTypeListForm from './OwnershipTypeForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const OwnershipTypeList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [ownershipTypeId, setOwnershipTypeId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.ownershipType.page);
  const totalData = useSelector((state) => state.ownershipType.total);
  const limit = useSelector((state) => state.ownershipType.limit);
  const totalPages = useSelector((state) => state.ownershipType.totalPages);
  const ownershipTypeList = useSelector((state) => state.ownershipType.ownershipTypes);
  const isLoader = useSelector((state) => state.ownershipType.isLoading);

  const getOwnershipType = useCallback(
    (data = {}) => {
      if (searchText) {
        const ownershipTypeData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_OWNERSHIP_TYPE.REQUEST, payload: ownershipTypeData });
      } else {
        dispatch({ type: FETCH_OWNERSHIP_TYPE.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  const getOwnershipTypeDetail = useCallback((id) => {
    dispatch({ type: GET_OWNERSHIP_TYPE.REQUEST, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setOwnershipTypeId('');
  };

  const deleteOwnershipType = useCallback(
    () =>
      dispatch({
        type: DELETE_OWNERSHIP_TYPE.REQUEST,
        payload: ownershipTypeId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getOwnershipType({ page: 1 });
          }
        },
      }),
    [ownershipTypeId]
  );

  useEffect(() => {
    getOwnershipType();
  }, []);

  const handleSearch = () => {
    getOwnershipType({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getOwnershipType(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getOwnershipType(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getOwnershipType(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormOwnershipType = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.ownershipType')} />
        <div className="page-content-wrapper scrollable">
          <div className="stationAccessTypeList-page">
            <Card>
              <div className="stationAccessTypeList-page-wrapper">
                <Row className="stationAccessTypeList-search-box">
                  <Col xl={10} lg={9} md={8} className="stationAccessTypeList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="stationAccessTypeList-search-boxButton">
                    <Button
                      className="stationAccessTypeList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addType')}
                    </Button>
                  </Col>
                </Row>
                <div className="stationAccessTypeList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('ownershipTypeListForm.nameLabel')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>{t('ownershipTypeListForm.action')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(ownershipTypeList) ? (
                          <tr>
                            <td colSpan={2} className="border-0">
                              <div className="empty-data-block">{t('ownershipTypeListForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(ownershipTypeList, (item, key) => {
                            return (
                              <>
                                <tr key={`stationAccessType-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>
                                    <span
                                      className="stationAccessTypeList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getOwnershipTypeDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="stationAccessTypeList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setOwnershipTypeId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(ownershipTypeList) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <OwnershipTypeListForm onClose={handleCloseFormOwnershipType} isEdit={isEdit} onSuccess={getOwnershipType} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="OwnershipType" onClose={handleCloseDeleteModel} onRemove={deleteOwnershipType} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default OwnershipTypeList;
